import { useContext, useState } from "react";
import Card from "@mui/material/Card";
import { AxiosContext } from "context";
import { toast } from "react-toastify";

import MDBox from "../../../components/CTFramework/MDBox";
import MDTypography from "../../../components/CTFramework/MDTypography";
import MDInput from "../../../components/CTFramework/MDInput";
import MDButton from "../../../components/CTFramework/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/bg-reset-cover.jpeg";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const { publicAxios } = useContext(AxiosContext);

  const handleResetWithMail = async () => {
    try {
      await publicAxios.post("/auth/v1/requestReset", { username: email });

      toast.success("E-mail enviado com sucesso. Por favor siga as instruções!");
    } catch (err) {
      if (err?.response?.data?.statusCode === 401) {
        toast.warn("Por favor verifique o e-mail informado!");
      } else {
        toast.error("Ocorreu um erro inesperado, por favor tente novamente!");
      }
    }
  };

  return (
    <BasicLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Resete sua senha
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Você reberá um e-mail com as instruções
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleResetWithMail}>
                Resetar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
