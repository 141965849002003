import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { setDarkMode, useAuth, useThemeController, AxiosContext } from "context";
import { toast } from "react-toastify";

import MDBox from "../../../components/CTFramework/MDBox";
import MDTypography from "../../../components/CTFramework/MDTypography";
import MDInput from "../../../components/CTFramework/MDInput";
import MDButton from "../../../components/CTFramework/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/bg-sign-in.png";

function SignIn() {
  const [controller, dispatch] = useThemeController();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { setAuthState, setCurrentUser } = useAuth();
  const { publicAxios } = useContext(AxiosContext);
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLoginWithMail = async () => {
    try {
      const response = await publicAxios.post("/auth/v1/login", { username: email, password });

      setAuthState({
        accessToken: response.data.token.accessToken,
        refreshToken: response.data.token.refreshToken,
        authenticated: true,
      });

      if (rememberMe) {
        localStorage.setItem("app_config", JSON.stringify(response.data));
      }

      setCurrentUser(response.data.user);
      setDarkMode(dispatch, response.data.user.configs?.theme === "dark" || controller.darkMode);

      navigate("/", { replace: true });
    } catch (err) {
      toast.error("Não foi possível autenticar em sua conta. Por favor verifique os dados!");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Acesse sua conta
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-mail"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Lembrar dos meus dados
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleLoginWithMail}>
                Entrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Esqueci minha senha
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Ainda não tem sua conta?{" "}
                <MDTypography
                  component={Link}
                  to="/cadastro"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Crie agora
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
